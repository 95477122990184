import Box from '@mui/material/Box'
import { RichTreeView, TreeViewBaseItem, useTreeViewApiRef } from '@mui/x-tree-view'
import { useRef } from 'react'

const getItemDescendantsIds = (item: TreeViewBaseItem) => {
  const ids: string[] = []
  item.children?.forEach((child) => {
    ids.push(child.id)
    ids.push(...getItemDescendantsIds(child))
  })
  return ids
}

type AreaSelectionPropsType = {
  areas: TreeViewBaseItem[],
  selectedAreas: string[],
  setSelectedAreas: (areas: string[]) => void,
  props?: any,
}

export const AreaSelection = ({ areas, selectedAreas, setSelectedAreas, props = {} }: AreaSelectionPropsType) => {

  const toggledItemRef = useRef<{ [itemId: string]: boolean }>({})
  const apiRef = useTreeViewApiRef()
  const lastClickTime = useRef(0)

  const handleDoubleClick = (clickedItemId: string) => {
    const clickedItem = (apiRef.current! as any).getItem(clickedItemId)
    const descendants = getItemDescendantsIds(clickedItem)
    setSelectedAreas([clickedItemId, ...descendants])
    toggledItemRef.current = {}
  }

  const handleClick = (newSelectedItems: string[]) => {
    setSelectedAreas(newSelectedItems)

    const itemsToSelect: string[] = []
    const itemsToUnSelect: { [itemId: string]: boolean } = {}

    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = (apiRef.current! as any).getItem(itemId)
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item))
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true
        })
      }
    })

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    )

    setSelectedAreas(newSelectedItemsWithChildren)
    toggledItemRef.current = {}
  }

  const handleSelectedItemsChange = (event: React.SyntheticEvent, newSelectedItems: string[]) => {
    // Based on time between clicks, determine if we should handle a single or double click
    // TODO: is there a better way to do this?
    const currentTime = new Date().getTime()
    const DOUBLE_CLICK_DELAY = 300 // milliseconds

    if (currentTime - lastClickTime.current < DOUBLE_CLICK_DELAY) {
      handleDoubleClick(newSelectedItems[0]) 
    } else {
      handleClick(newSelectedItems) 
    }
    lastClickTime.current = currentTime
  }

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean,
  ) => {
    toggledItemRef.current[itemId] = isSelected
  }

  return (
    <Box sx={{ minHeight: 352, minWidth: 290 }}>
      <RichTreeView
        { ...props }
        multiSelect
        checkboxSelection
        apiRef={apiRef}
        items={areas}
        selectedItems={selectedAreas}
        onSelectedItemsChange={handleSelectedItemsChange}
        onItemSelectionToggle={handleItemSelectionToggle}
      />
    </Box>
  )
}
