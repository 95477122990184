import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link as RouterLink, useNavigate } from "react-router-dom";
import { Logo } from "../app/Logo";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { isAdmin } from "../common/roleHelpers";
import { closeDrawer } from "../common/uiSlice";
import { setMunicipality as dispatchMunicipality, selectCurrentForecastId, selectCurrentMunicipalityCode, selectCurrentUser } from "../features/apis/appSlice";
import { selectCurrentForecast } from "../features/apis/forecastApi";
import { useGetMunicipalitiesQuery } from "../features/apis/projectionParametersSlice";
import { ForecastType } from "../types";

const MunicipalityDropdown = () => {

    const { t } = useTranslation()
    const currentMunicipality = useAppSelector(selectCurrentMunicipalityCode)
    const user = useAppSelector(selectCurrentUser) || {}

    const { data } = useGetMunicipalitiesQuery(undefined, {
        selectFromResult: ({ data }) => {
            const filteredData = (isAdmin(user) ? data : (data || []).filter(m => user.municipalities?.find(um => um.code === m.code))) || []
            return { data: [{ code: '', name: t('general.chooseMunicipality') }].concat([ ...filteredData].sort((m1, m2) => m1.name.localeCompare(m2.name, 'fi'))) }
        }
    })

    const dispatch = useAppDispatch()

    const setMunicipality = (code: string) => {
        const name = data?.find(municipality => municipality.code === code)?.name!
        dispatch(dispatchMunicipality({ code, name }))
    }

    return (
        (isAdmin(user) || (user.municipalities || []).length > 1) ?
        <select name="municipality-dropdown" value={currentMunicipality} onChange={({ target }) => setMunicipality(target.value)}>
            {(data || []).map(municipality => <option key={municipality.code} value={municipality.code}>{municipality.name}</option>)}
        </select>
        : null
    )
}

const EnvironmentSpan = () => {
    return process.env.REACT_APP_ENV === 'production' ? null : (
        <span style={{ padding: '5px', margin: '5px', backgroundColor: 'red', color: 'yellow', fontWeight: 700, fontSize: '1rem' }}>
            {process.env.REACT_APP_ENV?.toUpperCase()}
        </span>
    )
}

const TitleRow = () => {

    const { t } = useTranslation()
    const user = useAppSelector(selectCurrentUser) || {}

    return (

        <Box display={'inline-flex'} justifyContent={'flex-end'} className="popula-ai-header">
            <Box>
                <Typography variant="h1">{t('general.appTitle')}</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} >
                <EnvironmentSpan />
                <MunicipalityDropdown />
                <Typography sx={{ color: '#FFF', height: '20px', padding: '10px 10px' }}>{user.name}</Typography>
                <div style={{ padding: '10px' }}><Logo /></div>
            </Box>
        </Box>
    )
}

const ActionRow = () => {

    const selector = useMemo(() => {

        const selectMunicipality = (state: RootState) => state.appSlice.currentMunicipality?.name || ''

        return createSelector(
            selectMunicipality,
            selectCurrentForecast,
            (municipalityName, forecast) => ({ municipalityName, forecast })
        )
    }, [])

    const { municipalityName, forecast } = useAppSelector(selector)

    const getForecastString = ({ name, fromYear, toYear }: ForecastType) => ` / <u>${name} (${fromYear}-${toYear})</u>`

    const title = `
        ${municipalityName ? '<u>' + municipalityName + '</u>' : ''}
        ${forecast ? getForecastString(forecast) : ''}
    `

    return (
        <Grid2 xs={12} className='popula-ai-subheader' height={'45px'}>
            {
                municipalityName !== '' &&
                <Typography variant="h6" sx={{ paddingLeft: '5px', color: '#000' }}>
                    <span dangerouslySetInnerHTML={{ __html: title }}></span>
                </Typography>
            }
        </Grid2>
    )
}

const NavigationLink = ({ label, iconName, to, active = true }: { label: string, iconName: string, to: string, active?: boolean }) => {

    const dispatch = useAppDispatch()

    return (
        <Tooltip title={label} placement="right">
            <Link
                component={RouterLink}
                to={to}
                sx={{
                    color: active ? 'black' : 'gray',
                    textDecoration: 'none',
                    pointerEvents: active ? 'defaut' : 'none'
                }}
                onClick={() => dispatch(closeDrawer())}
            >
                <span className="material-icons-outlined">{iconName}</span>
            </Link>
        </Tooltip>
    )
}

const Navigation = () => {

    const { t } = useTranslation()
    const active = !!useAppSelector(selectCurrentForecastId)

    return (
        <Stack
            className="popula-ai-navigation"
        >
            <NavigationLink to="/" iconName='home' label={t('navigation.home')} />
            <NavigationLink to="/municipality" iconName='analytics' active={active} label={t('navigation.municipality')} />
            <NavigationLink to="/area" iconName='grid_view' active={active} label={t('navigation.area')} />
            <NavigationLink to="/building" iconName='construction' active={active} label={t('navigation.building')} />
            <NavigationLink to="/adjustment" iconName='balance' active={active} label={t('navigation.adjustment')} />
            <NavigationLink to="/query" iconName='query_stats' active={active} label={t('navigation.query')} />
        </Stack>
    )
}

const Content = () => (
    <Box sx={{
        width: '95%',
        margin: '5px',
        maxHeight: '100%',
    }}>
        <Outlet />

    </Box>
)

const Layout = () => {

    const navigate = useNavigate()

    const municipalityCode = useAppSelector(selectCurrentMunicipalityCode)

    useEffect(() => {
        navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ municipalityCode ])

    return (
        <Box>
            <Box>
                <TitleRow />
                <ActionRow />
            </Box>
            <Box display={'flex'}>
                <Stack direction="row">
                    <Navigation />
                    {municipalityCode && <Content />}
                </Stack>
            </Box>
        </Box>
    )
}

export default Layout