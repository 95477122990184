import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../app/store';
import { acquireToken } from '../../common/functions';
import { requestPending } from '../../common/uiSlice';
import { AdjustedProjectionsType } from '../../types';

export const baseUrl = (process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000') + '/api'

const setAuthHeaders = async (headers: Headers) => {

    const response = await acquireToken()
    headers.set('Authorization', `Bearer ${response.accessToken}`)

    return headers
}

export type FertilityForecastQueryParams = {
    municipalityCode: string,
    fromYear: string,
    toYear: string,
}

export type NaturalChangeForecastType = {
    year: string,
    deaths:number,
    births: number
}

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState,  }) => {

        let header = await setAuthHeaders(headers)

        let municipalityCode = (getState() as RootState).appSlice.currentMunicipality?.code
        header.set('municipality', municipalityCode!)

        return header
    }}
)

const requestRegister = (function() {

    let requestCounter = 0
  
    return {
  
      addRequest: () => {
        requestCounter++
      },
  
      removeRequest: () => {
        requestCounter--
      },
  
      isEmpty: () => !!!requestCounter
    }
  })()

const baseQueryListener: BaseQueryFn = async (args, api, extraOptions) => {

    const { dispatch } = api
    
    dispatch(requestPending(true))
    requestRegister.addRequest()
    
    const result = await baseQuery(args, api, extraOptions)
    
    requestRegister.removeRequest()
    dispatch(requestPending(!requestRegister.isEmpty()))
    result.error && alert(result.error.data + ' ' + api.endpoint)

    return result
}

type AdjustmentType = {
    [year: number]: {
        projections: number[]
        target: number
    }
}

type AdjustmentDataType = {
    adjusted_sums: AdjustmentType,
    unadjusted_sums: AdjustmentType,
}

export const populaApi = createApi({
    reducerPath: 'apiReducerPath',
    baseQuery: baseQueryListener,
    tagTypes: [
        'MigrationForecastPreview', 'ImmigrationForecast', 'EmigrationForecast', 'InterMunicipalMigrationInForecast',
        'InterMunicipalMigrationOutForecast', 'MortalityForecast', 'FertilityForecast', 'MigrationHistory', 'FertilityHistory',
        'MunicipalSummary', 'AreaSummary', 'BuildingProjectionSummary', 'BuildingTableData', 'Forecasts',
        'MunicipalData', 'AreaData', 'MunicipalSummaryData', 'AreaSummaryData',
        'AdjustedForecastSummary', 'AdjustedForecastData', 'BuildingTypes', 'NewConstructionRealizationPercentage',
        'GrowthLimitParameters'
    ],
    endpoints: (builder) => ({
        getAdjustedSummary: builder.query<AdjustmentDataType,{ forecastId:number }>({
            query: ({  forecastId }) => `projection/adjusted-summary/forecast/${forecastId}`,
            providesTags: ['AdjustedForecastSummary'],
        }),
        getAdjustedDataForecastForForecast: builder.query<AdjustedProjectionsType,{ forecastId:number }>({
            query: ({  forecastId }) =>
                `projection/adjusted-data/forecast/${forecastId}`,
            providesTags: ['AdjustedForecastData'],
        }),
    }),
})

export const {
    useGetAdjustedSummaryQuery,
    useGetAdjustedDataForecastForForecastQuery,
} = populaApi
